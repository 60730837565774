import { ERC721 } from "./gweis";

export default class Maxigoons extends ERC721 {
  static getAbi() {
    return ERC721.getAbi().concat([
      "function claim(bytes32[] memory proof) public",
      "function preSell(uint256 amount, bytes32[] memory proof) public payable",
      "function sell(uint256 amount) public payable",
      "function level() view returns (uint8)",
      "function enabled() view returns (bool)",
      "function hasLevel(uint8 index, address candidate, bytes32[] calldata proof) view returns (bool)",
    ]);
  }
}
