import { ContractTransaction } from "ethers";
import produce from "immer";
import { ethers } from "ethers";

const initialState = {
  address: "",
  leaf: "",
  leafs1: {},
  leafs2: {},
  web3: false,
  pending: "",
  hash: "",
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case "browser/fulfilled":
      state.web3 = true;
      break;

    case "signer/address":
    case "app/connect/fulfilled":
      state.address = (payload || "").toLowerCase();

      if (state.address) {
        state.leaf = ethers.utils.keccak256(state.address);
      }
      break;

    case "app/leafs": {
      state.leafs1 = payload[0];
      state.leafs2 = payload[1];
      break;
    }

    case "app/claim/tx":
    case "app/mint/tx": {
      state.hash = (payload as ContractTransaction).hash;
      break;
    }

    case "app/claim/pending":
    case "app/mint/pending": {
      state.hash = "";
      state.pending = "";
      break;
    }

    case "app/claim/fulfilled":
    case "app/mint/fulfilled": {
      state.pending = "success";
      break;
    }

    case "app/claim/rejected":
    case "app/mint/rejected": {
      state.pending = "error";
      break;
    }

    default:
      break;
  }
}, initialState);
