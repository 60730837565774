import React, { FC } from "react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

import config from "../config";
import { ExternalLink } from "../lib";

type Props = {
  id: string;
  failed?: boolean;
  mined?: boolean;
};

const Tx: FC<Props> = ({ id, failed = false, mined = false }) => {
  let icon;

  if (mined) {
    icon = <CheckIcon className="ml-1 h-5 w5 text-positive inline-block" />;
  } else if (failed) {
    icon = <XIcon className="ml-1 h-5 w5 text-negative inline-block" />;
  }

  return (
    <p className="p-4 align-middle">
      <ExternalLink
        href={`${config.etherscanUrl}/tx/${id}`}
        className="font-mono text-sm"
      >
        {id.slice(0, 10)}...{id.slice(-10)}
      </ExternalLink>
      {icon}
    </p>
  );
};

export default Tx;
