import produce from "immer";

const initialState = {
  claiming: false,
  connecting: false,
  loading: true,
  error: "",
  minting: false,
};

export default produce((state, { type, payload, meta }) => {
  switch (type) {
    case "app/leafs":
      state.loading = false;
      break;

    case "app/connect/pending":
      state.connecting = true;
      break;

    case "app/claim/pending":
      state.claiming = true;
      break;

    case "app/mint/pending":
      state.minting = true;
      break;

    case "app/claim/error":
      state.error = "You are not an Early Goon";
      break;

    case "app/mint/error":
      state.error = "You are not in the Goonlist";
      break;

    case "app/claim/rejected":
    case "app/mint/rejected":
      state.claiming = false;
      state.minting = false;
      state.error = "Your transaction failed.";
      break;

    case "browser/rejected":
    case "app/connect/rejected":
      state.connecting = false;
      state.error = "Your browser is not Web 3 compatible";
      break;

    case "contract/enabled":
      if (!payload) {
        state.error = "Sale is not yet open";
      }
      break;

    case "app/connect/fulfilled":
      state.connecting = false;
      break;

    case "app/claim/fulfilled":
      state.claiming = false;
      break;

    case "app/mint/fulfilled":
      state.minting = false;
      break;

    default:
      break;
  }
}, initialState);
