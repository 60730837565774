import { providers } from "ethers";

import { LocalhostProvider } from "./gweis";

export default function getClient({ chainId, infuraId }) {
  if (chainId === 1337) {
    return new LocalhostProvider();
  }

  return new providers.InfuraProvider(chainId, infuraId);
}
