import { from, fromEventPattern, of } from "rxjs";
import {
  bufferTime,
  catchError,
  filter,
  map,
  startWith,
  switchMap,
  take,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import { BigNumber, ContractTransaction, ethers, Signer } from "ethers";

const action = (type: string, payload?) => ({ type, payload });
const ofAction = (type: string, payload?) => of(action(type, payload));
const startWithAction = (type: string, payload?: any) =>
  startWith({ type, payload });

const price = ethers.utils.parseEther("0.049");

type TransferEventType = {
  from: string;
  to: string;
  id: BigNumber;
};

const events = [
  //
  // Boot
  //
  // Web3 ?
  (action$, state$, { getBrowser }) =>
    action$.pipe(
      take(1),
      switchMap(() => {
        return from(getBrowser()).pipe(
          map(() => action("browser/fulfilled")),
          catchError(() => ofAction("browser/rejected")),
          startWithAction("browser/pending")
        );
      })
    ),

  // Query transfers
  (action$, state$, { contract, client }) =>
    action$.pipe(
      take(1),
      switchMap(() =>
        from<Promise<TransferEventType[]>>(
          contract.connect(client).query("Transfer")
        ).pipe(
          map((transfers) =>
            action(
              "contract/transfers",
              transfers.map(({ from, to, id }) => ({
                from,
                to,
                id: id.toNumber(),
              }))
            )
          )
        )
      )
    ),

  // Sale level
  (action$, state$, { contract, client }) =>
    action$.pipe(
      take(1),
      switchMap(() =>
        from(contract.connect(client).level()).pipe(
          map((level) => action("contract/level", level))
        )
      )
    ),

  // Sale enabled
  (action$, state$, { contract, client }) =>
    action$.pipe(
      take(1),
      switchMap(() =>
        from(contract.connect(client).enabled()).pipe(
          map((enabled) => action("contract/enabled", enabled))
        )
      )
    ),

  (action$, state$, { getJSON }) =>
    action$.pipe(
      take(1),
      switchMap(() =>
        from(
          Promise.all([
            getJSON("/data/proofs-0.json"),
            getJSON("/data/proofs-1.json"),
          ])
        ).pipe(map(([leafs1, leafs2]) => action("app/leafs", [leafs1, leafs2])))
      )
    ),

  //
  // Runtime
  //

  // Address
  (action$, state$, { getBrowser }) =>
    action$.pipe(
      ofType("browser/fulfilled"),
      switchMap(() =>
        from(
          getBrowser().then((browser) =>
            browser
              .getAccount()
              .then((signer) =>
                signer ? signer.getAddress() : Promise.resolve("")
              )
          )
        ).pipe(map((address) => action("signer/address", address)))
      )
    ),

  // Listen to new Transfer events
  (action$, state$, { contract, client }) =>
    action$.pipe(
      ofType("contract/transfers"),
      take(1),
      switchMap(() =>
        fromEventPattern(
          (handler) => contract.connect(client).on("Transfer", handler),
          (handler) => contract.connect(client).off("Transfer", handler)
        ).pipe(
          bufferTime(1000),
          filter((arr) => arr.length > 0),
          map((arr) =>
            action(
              "app/transfers",
              arr.map((ev) => ({
                from: ev[0],
                to: ev[1],
                id: ev[2].toNumber(),
              }))
            )
          )
        )
      )
    ),

  // Connect
  (action$, state$, { getBrowser }) =>
    action$.pipe(
      ofType("app/connect"),
      switchMap(() =>
        from(
          getBrowser().then((browser) =>
            browser.requestAccount().then((signer) => signer.getAddress())
          )
        ).pipe(
          map((address) => action("app/connect/fulfilled", address)),
          catchError(() => ofAction("app/connect/rejected")),
          startWithAction("app/connect/pending")
        )
      )
    ),

  // Claim
  (action$, state$, { getBrowser, contract }) =>
    action$.pipe(
      ofType("app/claim"),
      switchMap(() => {
        const level = state$.value.contract.level;
        const leafs = state$.value.session.leafs1;

        return from<Promise<Signer>>(
          getBrowser().then((browser) => {
            return browser.requestAccount();
          })
        ).pipe(
          switchMap((signer) => {
            return from<Promise<string>>(signer.getAddress()).pipe(
              switchMap((address) => {
                const leaf = ethers.utils.keccak256(address);
                const proof = leafs[leaf];

                if (level < 3 && proof) {
                  return from<Promise<ContractTransaction>>(
                    contract.connect(signer).claim(proof)
                  ).pipe(
                    map((tx) => action("app/claim/tx", tx)),
                    catchError(() => ofAction("app/claim/rejected")),
                    startWithAction("app/claim/pending")
                  );
                }

                return ofAction("app/claim/error");
              })
            );
          })
        );
      })
    ),

  (action$) =>
    action$.pipe(
      ofType("app/claim/tx"),
      switchMap(({ payload: tx }: { payload: ContractTransaction }) =>
        from(tx.wait()).pipe(
          map(() => action("app/claim/fulfilled")),
          catchError(() => ofAction("app/claim/rejected"))
        )
      )
    ),

  // Pre-sell
  (action$, state$, { getBrowser, contract }) =>
    action$.pipe(
      ofType("app/preSell"),
      switchMap(({ payload: amount }) => {
        return from<Promise<Signer>>(
          getBrowser().then((browser) => browser.requestAccount())
        ).pipe(
          switchMap((signer) => {
            return from<Promise<string>>(signer.getAddress()).pipe(
              switchMap((address) => {
                const leafs = state$.value.session.leafs2;
                const leaf = ethers.utils.keccak256(address);

                const proof = leafs[leaf];

                if (proof) {
                  return from<Promise<ContractTransaction>>(
                    contract
                      .connect(signer)
                      .preSell(amount, proof, { value: price.mul(amount) })
                  ).pipe(
                    map((tx) => action("app/mint/tx", tx)),
                    catchError(() => ofAction("app/mint/rejected")),
                    startWithAction("app/mint/pending")
                  );
                }

                return ofAction("app/mint/error");
              })
            );
          })
        );
      })
    ),

  // Sell
  (action$, state$, { getBrowser, contract }) =>
    action$.pipe(
      ofType("app/sell"),
      switchMap(({ payload: amount }) => {
        return from(
          getBrowser().then((browser) =>
            browser
              .requestAccount()
              .then((signer) =>
                contract
                  .connect(signer)
                  .sell(amount, { value: price.mul(amount) })
              )
          )
        ).pipe(
          map((tx) => action("app/mint/tx", tx)),
          startWithAction("app/mint/pending")
        );
      })
    ),

  (action$) =>
    action$.pipe(
      ofType("app/mint/tx"),
      switchMap(({ payload: tx }: { payload: ContractTransaction }) =>
        from(tx.wait()).pipe(
          map(() => action("app/mint/fulfilled")),
          catchError(() => ofAction("app/mint/rejected"))
        )
      )
    ),
];

export default events;
