import produce from "immer";

const initialState = {
  enabled: false,
  balances: {},
  level: 0,
  owners: {},
  supply: 0,
};

export default produce((state, { type, payload, meta }) => {
  switch (type) {
    case "contract/level": {
      state.level = payload;
      break;
    }

    case "contract/enabled": {
      state.enabled = payload;
      break;
    }

    case "contract/transfers":
    case "app/transfers": {
      payload.forEach(({ from, to, id }) => {
        const registered = Boolean(state.owners[id]);

        if (!registered) {
          const _from = from.toLowerCase();
          const _to = to.toLowerCase();

          // From
          if (state.balances[_from]) {
            state.balances[_from]--;
          }

          // To
          if (!state.balances[_to]) {
            state.balances[_to] = 0;
          }

          state.balances[_to]++;

          // Id
          state.owners[id] = _to;

          // Supply
          state.supply++;
        }
      });
      break;
    }

    default:
      break;
  }
}, initialState);
