import React, { FC, HTMLAttributes, useState } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import images from "../images";
import useInterval from "../hooks/interval";
import { Col, Container, Row } from "../lib";

const carousel = [
  images.goon1,
  images.goon2,
  images.goon3,
  images.goon4,
  images.goon5,
  images.goon6,
  images.goon7,
  images.goon8,
  images.goon9,
  images.goon10,
  images.goon11,
];

export default function Home() {
  const [index, setIndex] = useState(0);

  useInterval(() => {
    setIndex((index + 1) % carousel.length);
  }, 500);

  return (
    <>
      <Hero />
      <Call2Action />
      <Story />
      <Genesis image={carousel[index]} />
      <Gnoog />
      <Roadmap />
      <Team />
      <FAQ />

      <footer className="relative pt-20">
        <img
          src={images.maxiblood}
          width="300"
          className="absolute bottom-0 left-0"
        />

        <div className="p-4 text-right sm:text-center">
          <small>Maxigoons 2022 &copy; All rights reserved</small>
        </div>
      </footer>
    </>
  );
}

type TitleProps = HTMLAttributes<HTMLElement> & {
  center?: boolean;
  huge?: boolean;
  small?: boolean;
  white?: boolean;
};

const Title: FC<TitleProps> = ({
  className,
  huge = false,
  small = false,
  white = false,
  ...rest
}) => {
  return (
    <h2
      className={cx(
        {
          "text-6xl sm:text-9xl": huge,
          "text-6xl": !huge && !small,
          "text-5xl": small,
          "text-white": white,
        },
        className
      )}
      {...rest}
    />
  );
};

const Lead: FC<HTMLAttributes<HTMLElement>> = ({ className, ...rest }) => {
  return <div className={cx("sm:text-xl", className)} {...rest} />;
};

type SurfaceProps = HTMLAttributes<HTMLElement> & {
  border?: string;
  full?: boolean;
  w?: string;
};

const Surface: FC<SurfaceProps> = ({
  border = "black",
  full = false,
  className,
  w = "md",
  ...rest
}) => {
  return (
    <div
      className={cx(
        "bg-white text-black rounded-3xl p-8 border-4 drop-shadow-md",
        {
          "sm:max-w-md": w === "md",
          "sm:max-w-xl": w === "xl",
          "border-black": border === "black",
          "border-galaxy": border === "galaxy",
          "min-w-full": full,
        },
        className
      )}
      {...rest}
    />
  );
};

const Hero: FC = () => {
  return (
    <div className="mx-auto max-w-5xl">
      <div className="min-h-screen relative">
        <img
          className="object-contain absolute bottom-0"
          src={images.goonMouetteTop}
        />
      </div>
      <img className="object-contain" src={images.goonMouetteBottom} />
    </div>
  );
};

const Call2Action: FC = () => {
  return (
    <div className="bg-orange py-8">
      <Container>
        <Col.C>
          <Title className="text-center mb-8">Sale is open!</Title>

          <Link
            to="mint"
            className="bg-black text-white font-medium rounded-xl py-4 px-6 text-center mb-8"
          >
            Mint
          </Link>

          <Row.C>
            <a
              href="https://twitter.com/MaxiGoons"
              target="_blank"
              className="mr-2"
            >
              <img src={images.twitter} width="50" />
            </a>
            <a href="https://discord.gg/TywbMqsC" target="_blank">
              <img src={images.discord} width="50" />
            </a>
          </Row.C>
        </Col.C>
      </Container>
    </div>
  );
};

const Story: FC = () => {
  return (
    <div className="relative bg-blue text-white py-16 overflow-hidden">
      <img
        src={images.nuage2}
        width="300"
        className="absolute bottom-24 -left-40"
      />
      <img
        src={images.nuage1}
        width="300"
        className="absolute -bottom-24 -right-16"
      />

      <Container>
        <Title huge className="relative mb-4">
          Vision &amp; Values
        </Title>

        <Lead className="mb-36">
          <p className="mb-4">
            More than an amazing hand drawn PFP project with vibrant colors, the
            Maxigoons project is the first stone of the Maxiverse deployment.
          </p>
          <p>
            We see the Maxiverse as an Omnichannel project creating bridges
            between the physical world and the interconnected digitals worlds
            with 4 Core values in everything we do
          </p>
        </Lead>

        <div className="mx-auto max-w-2xl relative">
          <img
            src={images.nuage2}
            width="300"
            className="absolute -top-28 -left-40"
          />
          <img
            src={images.nuage1}
            width="300"
            className="absolute top-48 -right-40"
          />
          <div className="my-8">
            <Surface>
              <div className="text-lg font-bold mb-1">Bringing value</div>
              <p>
                Our aim is to bring web3 a project that cares for its community,
                giving back not only in the form of digital assets but also real
                world products and experiences. Such as hand-crafted art (Goon
                sculpture anyone?), signed and exclusive merch, Maxiverse art
                gallery events, and so much more…
              </p>
            </Surface>
          </div>

          <div className="sm:flex sm:flex-row sm:justify-end mb-8">
            <Surface>
              <div className="text-lg font-bold mb-1">Community</div>
              <p>
                Our goal is to create a strong and fun community of Maxigoons in
                the web3 space, where our holders are the key decision makers on
                the direction of the project, building alongside of us.
              </p>
            </Surface>
          </div>

          <div className="sm:ml-16 mb-8">
            <Surface>
              <div className="text-lg font-bold mb-1">Fun</div>
              <p>
                Maxigoons are all about having fun, not taking ourselves too
                seriously, and providing entertainment.
              </p>
            </Surface>
          </div>

          <div className="sm:flex sm:flex-row sm:justify-end sm:mr-8">
            <Surface>
              <div className="text-lg font-bold mb-1">Caring for others</div>
              <p className="mb-2">
                As we are all kids at heart and have kids ourselves, we are
                deeply concerned with making the world a better place for the
                future.
              </p>
              <p>
                A percentage of all fees generated by secondary sales in the
                Maxiverse will go to a children's charity, indefinitely.
              </p>
            </Surface>
          </div>
        </div>
      </Container>
    </div>
  );
};

type GenesisProps = {
  image: string;
};

const Genesis: FC<GenesisProps> = ({ image }) => {
  return (
    <div className="bg-white py-16">
      <Container>
        <div className="sm:flex sm:flex-row">
          <div>
            <img
              src={image}
              width="300"
              className="border-4 border-black drop-shadow-md mb-2 sm:mr-8"
            />
          </div>
          <Col className="max-w-lg">
            <Title huge className="mb-4">
              #0001
            </Title>
            <div className="ml-4">
              <p className="mb-2">
                The story of the Maxigoons and their expansion in the universe
                goes back to a traumatic series of events on the planet Goon, in
                the <strong className="font-medium">Maxiverse</strong>…
              </p>
              <p className="mb-2">
                The Maxigoons were once{" "}
                <strong className="font-medium">highly intelligent</strong> and
                cunning creatures who had been thriving for millions of years,
                building an interstellar Empire that no one could defy.
              </p>

              <p className="mb-2">
                They drew their abilities from a resource previously infinitely
                abundant on their planet: The Gnoog. This very precious metal,
                when eaten regularly, enhance their Stem cell development to
                levels beyond our understanding.
              </p>
              <p>
                The Gnoog, previously abundant in the Maxiverse, is now almost
                extinct… Without it, the Maxigoons have started to{" "}
                <strong className="font-medium">degenerate rapidly</strong> and
                their empire is crumbling…
              </p>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
};

const Gnoog: FC = () => {
  return (
    <div className="bg-black text-white py-16">
      <Container>
        <Col.C>
          <Title className="mb-2">GNOOG</Title>
          <img src={images.gnoog} width="400" className="mb-4" />
          <div className="max-w-xl">
            <p className="mb-4">
              Element 169, known to the Maxigoons as Gnoog, is a precious metal
              once abundant in the Maxiverse and is now nearly extinct. Without
              Gnoog as part of their daily diet, the Maxigoons have started to
              rapidly degenerate, their beloved empire crumbling alongside. In a
              last ditch effort, the all powerful Maxigoons council will break
              the forbidden rule of interstellar travel in search of the last
              remaining Gnoog to save their world.
            </p>
            <p>
              Gnoog is a one of its kind farmable NFT ! By sending a Maxigoon on
              an adventure (Staking) you have a choice of different adventure
              paths with different rewards.
            </p>
          </div>
        </Col.C>
      </Container>
    </div>
  );
};

const Roadmap: FC = () => {
  return (
    <div className="relative bg-galaxy text-white overflow-hidden py-16 bg-stars">
      <Container>
        <div className="relative">
          <Title huge className="text-center mb-12">
            Roadmap
          </Title>

          <Surface border="galaxy" className="mx-auto" w="xl">
            <ol className="relative border-l border-galaxy">
              <TimelineItem date="Q1 2022">
                <p className="mb-2">
                  Art concept and Smart Contract development (ERC721a)
                </p>
                <p className="mb-2">7007 Maxigoons arriving in the metaverse</p>
                <p className="mb-2">
                  Goonlist Sale (March 5<sup>th</sup>)
                </p>
                <p className="mb-2">
                  Public Sale (March 6<sup>th</sup>)
                </p>
              </TimelineItem>

              <TimelineItem date="Q2 2022">
                <p className="mb-2">
                  Adventure Launch! Send your Maxigoons on adventures to collect
                  physical and NFT rewards…
                </p>
                <p className="mb-2">
                  Selection of the chosen charities that will be sponsored
                </p>
                <p>
                  Physical product productions (1/1 physical art, signed prints,
                  merch, customized sneakers…)
                </p>
              </TimelineItem>

              <TimelineItem date="Q3 & Q4 2022">
                <p className="mb-2">New Adventures!</p>

                <p className="mb-2">
                  Giving their intelligence back to your Maxigoons !
                </p>

                <p className="mb-2">
                  Upcoming drops of other Maxiverse characters
                </p>

                <p className="mb-2">
                  Set the stage for Maxigoons IRL events, starting with{" "}
                  <strong className="font-medium">Paris Art Basel</strong> with
                  a dedicated Maxiverse store (location secured)
                </p>
              </TimelineItem>

              <TimelineItem date="Q1 2023 (Exploring)">
                <p className="mb-2">
                  Mobile and Desktop interactive Game set in the Maxiverse. This
                  is something we will revisit upon the project selling out.
                </p>
              </TimelineItem>
            </ol>
          </Surface>
        </div>
      </Container>
    </div>
  );
};

type TimelineItemProps = {
  date: string;
};

const TimelineItem: FC<TimelineItemProps> = ({ date, children }) => {
  return (
    <li className="relative mb-8">
      <div className="flex flex-row mb-4">
        <div className="absolute w-4 h-4 bg-galaxy rounded-full top-0 -left-2" />
        <time className="ml-4 text-sm leading-none text-galaxy font-medium">
          {date}
        </time>
      </div>
      <div className="ml-8">{children}</div>
    </li>
  );
};

const Team: FC = () => {
  return (
    <div className="bg-blue py-16">
      <Title huge className="text-center mb-16">
        Core Team
      </Title>

      <Container>
        <div className="sm:grid sm:grid-cols-2 sm:gap-16">
          <Profile
            name="Drinas"
            image={images.maxigooner}
            social="https://twitter.com/Drinas15"
            className="mb-16 sm:mb-0"
          >
            Entrepreneur, helping crypto brands in their omnichannel development
            through events and retail. He is also a buy high sell low kind of
            guy, follow him for countertrades !
          </Profile>

          <Profile
            name="Marshall Batmann"
            image={images.maxinaut}
            social="https://instagram.com/marshall__batmann"
            className="mb-16 sm:mb-0"
          >
            Artist with multiple talent, he has never owned an NFT and doesn't
            even have a wallet. He is definitely{" "}
            <strong className="font-medium">NGMI</strong>.
          </Profile>

          <Profile
            name="0x55"
            image={images.maxiverse}
            social="https://twitter.com/maxigoon"
            className="mb-16 sm:mb-0"
          >
            He is the very first maxigoon and our talented developer with 15+
            years of experience. He got rugged for his first nft buy a year ago
            and still hasn’t gotten over it.
          </Profile>

          <Profile
            name="0xBuffalo"
            image={images.boredApe}
            social="https://twitter.com/0xBuffalo"
          >
            Stumbling onto the crypto scene in 2016, this web3 native enjoys
            long strolls through the Metaverse at sunset. Passionate in
            spreading the word of Blockchain and helping to onboard Newbs any
            chance he can get.
          </Profile>
        </div>
      </Container>
    </div>
  );
};

type ProfileProps = {
  className?: string;
  image: string;
  name: string;
  social: string;
};

const Profile: FC<ProfileProps> = ({
  children,
  className,
  image,
  name,
  social,
}) => {
  return (
    <div className={className}>
      <Surface>
        <div className="flex flex-row justify-center">
          <img src={image} width="300" className="rounded-xl mb-4" />
        </div>
        <Title small className="mb-2">
          {name}
        </Title>

        <div className="mb-4">{children}</div>

        <a
          className="block text-blue underline text-ellipsis overflow-hidden"
          href={social}
          target="_blank"
        >
          {social}
        </a>
      </Surface>
    </div>
  );
};

const FAQ: FC = () => {
  return (
    <div className="py-16">
      <Container>
        <Title huge className="mb-16">
          FAQs
        </Title>

        <div className="mx-auto max-w-xl">
          <div className="mb-16">
            <h3 className="text-6xl mb-4">
              Wen <del>moon</del> goon?
            </h3>
            <Surface w="xl">
              <p className="mb-2">
                Pre-sale (whitelist) will open on Saturday{" "}
                <strong className="font-medium">March 5th</strong>.
              </p>
              <p>
                Public sale (FCFS) will take place 24h after, on{" "}
                <strong className="font-medium">March 6th</strong>.
              </p>
            </Surface>
          </div>

          <div className="mb-16">
            <h3 className="text-6xl mb-4">Mint price?</h3>
            <Surface w="xl">
              <p className="mb-2">
                The mint price is <code>0.049 ETH</code> / Goon.
              </p>
              <p className="mb-2">
                WL spots will be able to mint{" "}
                <strong className="font-medium">3 per wallet</strong> (+1 free
                mint for "Early Goons" roles)
              </p>
              <p className="mb-2">
                Public sale will be able to mint{" "}
                <strong className="font-medium">10 per TX</strong>
              </p>

              <p>
                Token will be an{" "}
                <strong className="font-medium">ERC721a</strong> making you save
                a ton on gas price.
              </p>
            </Surface>
          </div>

          <div>
            <h3 className="text-6xl mb-4">What is the supply?</h3>
            <Surface w="xl">
              <strong className="block font-medium mb-2">
                Each Maxigoon is unique
              </strong>

              <p className="mb-2">
                There will be <code>7007</code> Maxigoons in existence with
                different rarities and custom made 1/1's.
              </p>
              <p>
                <code>707</code> Maxigoons (~10%) will be kept in the Treasury,
                around 70% of those will be used for adventures rewards and the
                rest for marketing, community giveaways and team compensation.
              </p>
            </Surface>
          </div>
        </div>
      </Container>
    </div>
  );
};
