import React from "react";
import { ethers } from "ethers";

import config from "../config";
import images from "../images";
import { useSelector, useDispatch } from "../hooks";
import { Button, Caption, Col, ExternalLink, Text } from "../lib";
import MintForm from "./mint-form";
import Tx from "./tx";

export default function Minter() {
  // Hooks
  const dispatch = useDispatch();

  // UI
  const error = useSelector((state) => state.ui.error);
  const connecting = useSelector((state) => state.ui.connecting);
  const claiming = useSelector((state) => state.ui.claiming);
  const loading = useSelector((state) => state.ui.loading);
  const minting = useSelector((state) => state.ui.minting);

  // Session
  const address = useSelector((state) => state.session.address);
  const leaf = useSelector((state) => state.session.leaf);
  const hash = useSelector((state) => state.session.hash);
  const leafs1 = useSelector((state) => state.session.leafs1);
  const leafs2 = useSelector((state) => state.session.leafs2);
  const pending = useSelector((state) => state.session.pending);
  const web3 = useSelector((state) => state.session.web3);

  // Contract
  const balances = useSelector((state) => state.contract.balances);
  const enabled = useSelector((state) => state.contract.enabled);
  const level = useSelector((state) => state.contract.level);
  const supply = useSelector((state) => state.contract.supply);

  // Methods
  const handleConnect = () => dispatch("app/connect");

  const handleClaim = () => dispatch("app/claim");

  const handlePreSell = (amount) => dispatch("app/preSell", amount);

  const handleSell = (amount) => dispatch("app/sell", amount);

  // DOM
  const proof0 = Boolean(leafs1[leaf]);
  const proof1 = Boolean(leafs2[leaf]);

  const balance = balances[address];

  const claimed = proof0 && balance >= 1;
  const minted = proof1 && balance >= (proof0 ? 4 : 3);

  const ended = supply === config.maxSupply;

  let content;

  if (loading || !address || !enabled) {
    content = (
      <Button
        loading={connecting || loading}
        disabled={!enabled || !web3}
        white
        onClick={handleConnect}
      >
        Connect Wallet
      </Button>
    );
  } else if (level === 2) {
    content = (
      <MintForm
        max={10}
        disabled={ended}
        loading={minting}
        onSubmit={handleSell}
      />
    );
  } else {
    content = (
      <div className="flex flex-row">
        <Col.C className="mr-2">
          <Button
            disabled={!proof0 || claimed}
            loading={claiming}
            className="mb-2"
            onClick={handleClaim}
          >
            Claim
          </Button>
          <Caption>(Early goons)</Caption>
        </Col.C>

        <Col.C>
          <MintForm
            max={3}
            disabled={level < 1 || !proof1 || minted}
            loading={minting}
            onSubmit={handlePreSell}
            className="mb-2"
          />
          <Caption>(Goonlist)</Caption>
        </Col.C>
      </div>
    );
  }

  return (
    <Col.C>
      <img src={images.goonShade} width="300" className="mt-16 mb-4" />

      <div className="mb-8">{content}</div>

      {Boolean(hash) && (
        <Tx
          id={hash}
          failed={pending === "error"}
          mined={pending === "success"}
        />
      )}

      {Boolean(error) && <p className="text-negative p-4">{error}</p>}

      <Text>
        {supply}/{config.maxSupply} minted
      </Text>

      {ended && <p className="text-negative p-4">Sold out !</p>}

      {balance > 0 && (
        <p className="mt-2">
          <ExternalLink
            href={`${config.openSeaUrl}/account`}
            className="text-sm"
          >
            You own {balance} goon
          </ExternalLink>
        </p>
      )}
    </Col.C>
  );
}
