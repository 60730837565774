import React, { FC } from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import cx from "classnames";

import Link, { LinkProps } from "./link";

const ExternalLink: FC<LinkProps> = ({ className, children, ...rest }) => {
  return (
    <Link className={cx("align-middle", className)} {...rest} target="_blank">
      {children}
      <ExternalLinkIcon className="ml-1 h-5 w-5 inline-block" />
    </Link>
  );
};

export default ExternalLink;
