import config from "../config";

import getClient from "./client";
import Maxigoons from "./maxigoons";

import getBrowser from "./browser";
import getJSON from "./json";

const client = getClient(config);

const contract = new Maxigoons(config.contractAddress);

const services = {
  client,
  contract,
  getBrowser,
  getJSON,
};

export default services;
